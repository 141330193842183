const env = {
    COOKIE_DOMAIN: '.ultradent.com',
    API_ORIGIN: 'https://devapi.ultradent.com/partners/v1',
    CDN_ORIGIN: 'https://devmy.cdn.ultradent.com',
    UPI_TENANT_ID: 'f59af1b5-74af-43c1-85fc-99356f5e888aXXX',
    PARTNER_PORTAL_APP_ID: '70870bfa-fa9c-4395-924d-beca11637d12',
    PARTNER_PORTAL_API_APP_ID: 'f8d7b2d8-9630-4693-8f32-be4447656aa2',
    CRM_LOGIN_URL: 'https://ultradentintldev.crm4.dynamics.com/main.aspx?appid=66bf10c3-7f4e-4d69-849a-76393a882e53',
    API_TOKEN: 'partner_token_devmy',
    API_TOKEN_ANON: 'anonymous_token',
    API_KEY: process.env.REACT_APP_API_KEY,
    APPLICATION_INSIGHTS: {
        connectionString: 'InstrumentationKey=8c252e93-3a41-475a-b14c-416bc58ee39e;IngestionEndpoint=https://westus2-2.in.applicationinsights.azure.com/',
        loggingLevelConsole: 2
    }
};

export default env;
